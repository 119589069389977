import { Typography } from "antd";
import { ParagraphProps as AntdParagrapProps } from "antd/es/typography/Paragraph";

const { Paragraph: AntdParagraph } = Typography;

type ParagraphProps = AntdParagrapProps;

export function Paragraph(props: ParagraphProps) {
  return <AntdParagraph {...props}>{props.children}</AntdParagraph>;
}
