import { z } from "zod";
import { isDefined } from "../typechecks";

/**
 * Creates a validator for records that makes values possibly undefined.
 * For use with records that have arbitrary strings as keys, which makes them
 * neither exhaustive nor type safe.
 */
export function ZUnsafeRecord<
  RecordKey extends z.ZodString | z.ZodNumber | z.ZodSymbol,
  RecordValue extends z.ZodTypeAny,
>(key: RecordKey, value: RecordValue) {
  return z.record(key, value.or(z.undefined()));
}

/**
 * Refine any partial schema to make sure no undefined values were explicity
 * added to any of the keys.
 */
export function partialNotUndefined<T extends z.ZodTypeAny>(
  partialSchema: T
): z.ZodEffects<T> {
  return partialSchema.superRefine((val, ctx) => {
    for (const [k, v] of Object.entries(val)) {
      // best way would be to check v === undefined,
      // but if we do that we get Jest issues with Zod that it loses that zod reference.
      if (!isDefined(v) && v !== null) {
        ctx.addIssue({
          code: "custom",
          path: [k],
          message: `${k} can be omitted, but not undefined.`,
        });
      }
    }
  });
}
