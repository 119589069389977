import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../baseQuery";
import {
  ErrorName,
  PopulateCacheBody,
  PopulateCacheJobSchema,
  getErrorName,
  isDefined,
} from "@timeedit/registration-shared";

export const cacheApiSlice = createApi({
  reducerPath: "cacheApi",
  baseQuery,
  tagTypes: ["PopulateCacheStatus"],
  endpoints(builder) {
    return {
      populateCache: builder.mutation<void, PopulateCacheBody | void>({
        query(body) {
          return {
            url: "/populate-cache",
            body,
            method: "POST",
          };
        },
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          // When a populate cache job is started, set status to pending.
          try {
            dispatch(
              cacheApiSlice.util.updateQueryData(
                "populateCacheStatus",
                undefined,
                (draft) => {
                  if (isDefined(draft)) {
                    draft.status = "pending";
                    return;
                  }
                  return {
                    jobId: "fake_job",
                    name: "Registration - Populate Cache",
                    status: "pending",
                    error: undefined,
                    lastRunAt: Date.now(),
                  };
                }
              )
            );
            await queryFulfilled;
          } catch (error) {
            // If the user reloaded the page they might be able to press the
            // button again, then we will get this named error and we simply
            // set the status to pending and wait for the original job to
            // finish.
            const errorName = getErrorName(error);
            if (errorName === ErrorName.PopulateCacheBusyError) {
              dispatch(
                cacheApiSlice.util.updateQueryData(
                  "populateCacheStatus",
                  undefined,
                  (draft) => {
                    if (isDefined(draft)) {
                      draft.status = "pending";
                      return;
                    }
                    return {
                      jobId: "fake_job",
                      name: "Registration - Populate Cache",
                      status: "pending",
                      error: undefined,
                      lastRunAt: Date.now(),
                    };
                  }
                )
              );
            }
            throw error;
          }
        },
        invalidatesTags: ["PopulateCacheStatus"],
      }),
      populateCacheStatus: builder.query<
        PopulateCacheJobSchema | undefined,
        void
      >({
        query() {
          return {
            url: "/populate-cache-status",
            method: "GET",
            extraOptions: {
              validation: PopulateCacheJobSchema,
            },
          };
        },
        onQueryStarted: async (
          _,
          { dispatch, queryFulfilled, getCacheEntry }
        ) => {
          // If the local cache is empty and the request for status gets back
          // something other than pending, we don't want to set the status,
          // since it would be confusing to see 'completed' or 'failed'
          // before any interaction.
          const current = getCacheEntry()?.data;

          const { data } = await queryFulfilled;

          if (!isDefined(current?.status) && data?.status !== "pending") {
            dispatch(
              cacheApiSlice.util.updateQueryData(
                "populateCacheStatus",
                undefined,
                (draft) => {
                  if (isDefined(draft)) draft.status = "uninitialized";
                }
              )
            );
          }
        },

        providesTags: ["PopulateCacheStatus"],
      }),
      fetchClearCache: builder.mutation<null, void>({
        query() {
          return {
            url: "/clear-cache",
            method: "POST",
          };
        },
      }),
      fetchPopulateUserObjects: builder.mutation<null, void>({
        query() {
          return {
            url: "/populate-user-objects",
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {
  useFetchPopulateUserObjectsMutation,
  usePopulateCacheMutation,
  usePopulateCacheStatusQuery,
  useFetchClearCacheMutation,
} = cacheApiSlice;
