import { z } from "zod";

/** Expected feature flags with fallback values. Note: flags will automatically
 * be turned into camelCase, e.g. MY-FLAG => myFlag */
export const AllocateFeatureFlags = z.object({
  showOptimizeBulkAllocation: z.boolean().default(false),
  showLegacyAllocation: z.boolean().default(true),
  limitToAdminsOnly: z.string().default(""),
  showOptimizeAll: z.boolean().default(false),
});
export type AllocateFeatureFlags = z.infer<typeof AllocateFeatureFlags>;
