/* eslint-disable import/no-unused-modules */
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./registrationCalendar.scss";
import dayjs from "dayjs";
import {
  Calendar,
  dayjsLocalizer,
  CalendarProps,
  Components,
  Views,
  Formats,
  EventPropGetter,
} from "react-big-calendar";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AllocationStatus,
  Registration,
  Mapping,
  MinMaxTimestamps,
  RegistrationMode,
  ScheduleEvent,
  UnsafeRecord,
  isDefined,
} from "@timeedit/registration-shared";
import { Modal } from "antd";
import { useEventModal } from "./hooks";
import cn from "classnames";
import { CalendarEvent, createEvents } from "../../../utils/events";
import {
  RangeReduce,
  SelectOptions,
  limitedToRange,
  selectOptions,
  sortEventsOnTrackNumber,
} from "../utils";
import {
  CalendarToolbar,
  DisplayState,
} from "./CalendarToolbar/CalendarToolbar";
import { RegistrationRequiredTranslations } from "../types";
import { CalendarHeader } from "./CalendarHeader/CalendarHeader";
import { EventWrapper, EventWrapperProps } from "./CalendarEvents/EventWrapper";
import { EventModalContentProps } from "./CalendarModalContent/AllocationObjectModalContent";

const calendarLocalizer = dayjsLocalizer(dayjs);

const defaultHourDate = "1970-01-02T01:00:00";

/* eslint-disable-next-line no-console */
const originalError = console.error;
const { suppressError, unSuppressError } = errorSuppression(originalError);

interface MinMaxHours {
  minHours: number;
  maxHours: number;
}

interface RegistrationCalendarProps<EventType extends object>
  extends Omit<CalendarProps<EventType, object>, "localizer" | "events"> {
  extraEvents?: CalendarEvent[];
  extraReservations?: ScheduleEvent[];
  eventModalContent?: (props: EventModalContentProps) => JSX.Element;
  registration: Registration;
  dateInterval: MinMaxTimestamps;
  allowedDateInterval: MinMaxTimestamps;
  mapping: Mapping;
  translations: RegistrationRequiredTranslations;
  mode?: RegistrationMode;
  trackListId?: number;
  title: JSX.Element;
}

export function RegistrationCalendar(
  props: RegistrationCalendarProps<CalendarEvent>
) {
  handleErrorSuppression();

  const {
    dayLayoutAlgorithm = "no-overlap",
    onNavigate = () => {
      return;
    },
    extraEvents = [],
    extraReservations = [],
    views = { agenda: true, week: true },
    eventModalContent: createEventModalContent = () => <div></div>,
    registration,
    allowedDateInterval,
    dateInterval: propsDateInterval,
    mapping,
    translations,
    mode,
    trackListId,
  } = props;

  // Arbitrary week to shown in the calendar, chosen to be in the middle of the
  // year and month to avoid problems when setting the time matched dates for
  // events.
  const date = "2024-08-05";
  const eventModal = useEventModal();

  const [{ minHours, maxHours }, setMinMaxHours] = useState<MinMaxHours>({
    minHours: 8,
    maxHours: 18,
  });

  const initialDisplayState = {
    student: DisplayState.Text,
    teacher: DisplayState.Text,
  } satisfies Record<RegistrationMode, DisplayState>;

  const [displayState, setDisplayState] = useState(
    isDefined(mode) ? initialDisplayState[mode] : DisplayState.Graphical
  );
  const [viewOption, setViewOption] = useState<SelectOptions>("All weeks");
  const [dateInterval, setDateInterval] =
    useState<MinMaxTimestamps>(propsDateInterval);

  useEffect(() => {
    setDateInterval(propsDateInterval);
  }, [propsDateInterval]);

  const [startIntervalDate, endIntervalDate] = intervalDates();
  const [allowedStartIntervalDate, allowedEndIntervalDate] =
    allowedIntervalDates();

  const reservationEvents = eventReservations();
  const eventsWithinSpan = findEventsWithinSpan();
  const uniqueTimeMatchedEvents = createUniqueEvents();
  const sortedEvents = useMemo(
    () =>
      sortEventsOnTrackNumber({
        events: uniqueTimeMatchedEvents,
        mapping,
        tracks: registration.tracks,
        reverse: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uniqueTimeMatchedEvents]
  );

  const selectedTrackListEvents = useMemo(
    () =>
      isDefined(trackListId)
        ? sortedEvents.filter((e) => {
            const trackId =
              registration.tracks[e.data.allocationObjectId]?.parentId;
            if (!isDefined(trackId)) return false;
            return registration.trackLists[trackId]?.id === trackListId;
          })
        : sortedEvents,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedEvents, trackListId]
  );

  const eventsOverlapping = useMemo(
    () => createEventsOverlapping({ events: selectedTrackListEvents }),
    [selectedTrackListEvents]
  );

  const components = props.components ?? createDefaultComponents();
  const eventPropGetter =
    props.eventPropGetter ?? createDefaultEventPropGetter();
  const onSelectEvent = props.onSelectEvent ?? createDefaultOnSelectEvent();
  const formats = props.formats ?? createDefaultFormats();

  const eventsAndOverlapping = useMemo(() => {
    return isDefined(eventModal.modalInfo?.id)
      ? [
          eventModal.modalInfo,
          ...(eventsOverlapping[eventModal.modalInfo.id] ?? []),
        ]
      : [];
  }, [eventModal.modalInfo, eventsOverlapping]);

  const eventsWithRelated = useMemo(() => {
    return eventsAndOverlapping.map((event) => {
      const trackId = event.data.allocationObjectId;
      const linkIds = registration.tracks[trackId]?.links;

      return {
        event,
        relatedEvents: selectedTrackListEvents.filter((e) => {
          const sameIdOrLinked =
            trackId === e.data.allocationObjectId ||
            linkIds?.includes(e.data.allocationObjectId);
          return sameIdOrLinked && event.id !== e.id;
        }),
      };
    });
  }, [eventsAndOverlapping, registration.tracks, selectedTrackListEvents]);

  const eventModalContent = createEventModalContent({
    title: props.title,
    eventsWithRelated,
  });

  return (
    <>
      <Calendar<CalendarEvent, object>
        {...props}
        events={sortedEvents}
        dayLayoutAlgorithm={dayLayoutAlgorithm}
        onNavigate={onNavigate}
        date={date}
        components={components}
        views={views}
        defaultView={
          displayState === DisplayState.Graphical
            ? getGraphicalView(viewOption)
            : Views.AGENDA
        }
        min={createHourDate(minHours)}
        max={createHourDate(maxHours)}
        onSelectEvent={onSelectEvent}
        formats={formats}
        localizer={calendarLocalizer}
        eventPropGetter={eventPropGetter}
      />
      {/* Modal for every event */}
      <Modal
        className={
          "bigmodal" + (mode === "teacher" ? " teachermodal" : " studentmodal")
        }
        width={"auto"}
        open={eventModal.showModal}
        onCancel={() => eventModal.setShowModal(false)}
        footer={false}
      >
        {eventModalContent}
      </Modal>
    </>
  );

  function createDefaultEventPropGetter() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCallback<EventPropGetter<CalendarEvent>>(
      (event, _start, _end, _isSelected) => {
        const allocationStatus =
          registration.tracks[event.data.allocationObjectId]?.allocationStatus;

        if (!isDefined(allocationStatus)) {
          return {};
        }

        const classNames = [];
        switch (allocationStatus) {
          case "ALLOCATED_TO_THIS": {
            classNames.push(
              "rbc-event--base--filled",
              "rbc-event__border",
              "rbc-event__border--green"
            );
            break;
          }
          case "ALLOCATED_TO_OTHER": {
            classNames.push(
              "rbc-event--base",
              "rbc-event__border",
              "rbc-event__border--green"
            );

            break;
          }
          case "MULTIPLE_ALLOCATIONS":
            classNames.push(
              "rbc-event--base--error",
              "rbc-event__border",
              "rbc-event__border--red"
            );
            break;
          case "NOT_ALLOCATED":
            classNames.push("rbc-event--other");
            break;
        }
        allocationStatus satisfies AllocationStatus;

        event.title = (
          <div>
            <strong>
              {translations.weekAbbreviation}:{" "}
              {RangeReduce.pairPresent(event.data.reservedWeeks ?? [])}
            </strong>
            <br /> {event.data.name}
          </div>
        );
        return {
          className: cn(...classNames),
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [registration]
    );
  }

  function handleErrorSuppression() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMemo(() => {
      suppressError();
    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      suppressError();
      return () => {
        unSuppressError();
      };
    }, []);
  }

  function intervalDates() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      const startInterval = dayjs
        .unix(dateInterval.minTimestamp)
        .startOf("w")
        .toDate();
      const endInterval = dayjs
        .unix(dateInterval.maxTimestamp)
        .endOf("w")
        .toDate();

      return [startInterval, endInterval];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateInterval]);
  }

  function allowedIntervalDates() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      const startInterval = dayjs
        .unix(allowedDateInterval.minTimestamp)
        .startOf("w")
        .toDate();
      const endInterval = dayjs
        .unix(allowedDateInterval.maxTimestamp)
        .endOf("w")
        .toDate();

      return [startInterval, endInterval];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedDateInterval]);
  }

  function createDefaultComponents() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo((): Components<CalendarEvent> => {
      return {
        header: (props) => <CalendarHeader {...props} />,
        toolbar: (props) => (
          <CalendarToolbar
            {...props}
            setDisplayState={setDisplayState}
            displayState={displayState}
            setViewOption={setViewOption}
            viewOption={viewOption}
            setDateInterval={setDateInterval}
            dateInterval={dateInterval}
            allowedDateInterval={allowedDateInterval}
            translations={translations}
          />
        ),
        eventWrapper: (props) => {
          // props has children and other properties which rbc types do not represent
          const wrapperProps = props as EventWrapperProps;
          return <EventWrapper {...wrapperProps} viewOption={viewOption} />;
        },
        agenda: {
          event: (e) => <div>{e.title}</div>,
        },
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewOption]);
  }

  function createUniqueEvents() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      if (eventsWithinSpan?.length === 0) {
        return [];
      }

      const events = makeUniqueEvents(eventsWithinSpan ?? []);

      const year = dayjs(date).year();
      const week = dayjs(date).week();
      const timeMatchedEvents = setEventTimes({
        year,
        week,
        events,
      });

      setMinMaxHours(({ minHours, maxHours }) => {
        const { min, max } = eventsMinMaxHourRange({
          events: timeMatchedEvents,
          minHours,
          maxHours,
        });

        if (minHours !== min || maxHours !== max) {
          return { minHours: min, maxHours: max };
        }
        return { minHours, maxHours };
      });

      return timeMatchedEvents;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsWithinSpan]);
  }

  function eventReservations() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      const filteredReservations = (registration.events ?? []).filter(
        (event) => {
          if (!isDefined(event)) return false;
          return (
            registration.tracks[event.trackId]?.allocationStatus ===
              "ALLOCATED_TO_THIS" ||
            registration.tracks[event.trackId]?.allocationStatus ===
              "MULTIPLE_ALLOCATIONS"
          );
        }
      );
      const reservationEvents = createEvents({
        reservations: [...filteredReservations, ...extraReservations].filter(
          isDefined
        ),
      });

      // Important that extraEvents is passed in first
      // Otherwise if there is a identical event in extraEvents and reservationEvents
      // later when we filter on uniqueness the event from extraEvent will be removed
      // Which will change the order in the calendar
      // Then reserved event will always be first in calendar GUI(to the right)
      return [...extraEvents, ...reservationEvents];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      registration.events,
      registration.tracks,
      props.extraEvents,
      props.extraReservations,
    ]);
  }

  function findEventsWithinSpan() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      const [startInterval, endInterval] =
        viewOption === "All weeks"
          ? [allowedStartIntervalDate, allowedEndIntervalDate]
          : [startIntervalDate, endIntervalDate];

      return (
        reservationEvents.filter(({ start, end }) => {
          return (
            dayjs(start).isSameOrAfter(startInterval) &&
            dayjs(end).isSameOrBefore(endInterval)
          );
        }) ?? []
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      reservationEvents,
      viewOption,
      startIntervalDate,
      endIntervalDate,
      allowedStartIntervalDate,
      allowedEndIntervalDate,
    ]);
  }

  function createDefaultOnSelectEvent() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCallback((event: CalendarEvent) => {
      eventModal.setShowModal(true);
      eventModal.setModalInfo(event);
    }, []);
  }

  function createDefaultFormats() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo((): Formats => {
      return {
        agendaDateFormat: (date) => {
          return dayjs(date).format("dddd");
        },
        timeGutterFormat: (date) => {
          // TODO: Support AM and PM formats?
          return dayjs(date).format("HH");
        },
      };
    }, []);
  }

  function createHourDate(hour: number) {
    const date = new Date(defaultHourDate);
    date.setHours(hour, 0, 0, 0);
    return date;
  }
}

interface AdjustCalendarHourRange {
  events: CalendarEvent[];
  maxHours: number;
  minHours: number;
}
/**
 * Change the calendar hour range to match earliest and latest event if they are before or after the default range.
 * @param events The calendar events based on reservations
 * @param maxHours Initial max hour range
 * @param minHours Initial min hour range
 */
export function eventsMinMaxHourRange({
  events,
  maxHours,
  minHours,
}: AdjustCalendarHourRange) {
  let latestHour = maxHours;
  let earliestHour = minHours;

  events.forEach((event) => {
    const beginDate = event.start;
    const endDate = event.end;
    if (beginDate === undefined || endDate === undefined) {
      return;
    }

    if (beginDate.getHours() <= earliestHour) {
      earliestHour = beginDate.getHours();
    }

    if (endDate.getHours() >= latestHour) {
      latestHour = endDate.getHours();
      const endDateMin = endDate.getMinutes();
      const endDateSec = endDate.getSeconds();
      if (endDateMin > 0 || endDateSec > 0) {
        latestHour++;
      }
    }
  });

  // The calendar min max range can not go all the way to hour 24,
  // or it will result in an invalid array length inside react big calendar.
  return {
    min: limitedToRange(earliestHour, 0, latestHour),
    max: limitedToRange(latestHour, earliestHour, 23),
  };
}

export function getGraphicalView(viewKey: SelectOptions) {
  return selectOptions()[viewKey];
}

/**
 *
 * @param events An array of events for calendar
 * @returns A unique list of events based on day and hour time, that contains the weeks when the duplicated events are scheduled.
 */
export function makeUniqueEvents(events: CalendarEvent[]): CalendarEvent[] {
  return events.reduce<CalendarEvent[]>((savedEvents, event) => {
    const beginDate = event.start;
    const endDate = event.end;
    const eventName = event.data?.name;

    if (!isDefined(beginDate) || !isDefined(endDate) || !isDefined(eventName)) {
      return savedEvents;
    }

    const existingEvent = savedEvents.find((savedEvent) => {
      const savedBeginDate = savedEvent.start;
      const savedEndDate = savedEvent.end;
      const savedEventName = savedEvent.data?.name;

      const hasDates = isDefined(savedBeginDate) && isDefined(savedEndDate);
      return (
        hasDates &&
        isDefined(savedEventName) &&
        shouldMergeEvents({ event1: savedEvent, event2: event })
      );
    });

    if (existingEvent === undefined) {
      const reservedWeeks = [dayjs(beginDate).week()];
      const newEvent: CalendarEvent = {
        ...event,
        data: {
          ...event.data,
          reservedWeeks,
          name: event.data?.name ?? "",
        },
      };
      return [...savedEvents, newEvent];
    }

    existingEvent.data.reservations.push(...event.data.reservations);

    const week = dayjs(beginDate).week();
    if (!existingEvent.data?.reservedWeeks?.includes(week)) {
      existingEvent.data?.reservedWeeks?.push(week);
    }
    if (!existingEvent.data.hasConflict) {
      existingEvent.data.hasConflict = event.data.hasConflict;
    }

    return savedEvents;
  }, []);
}

interface ShouldMergeEvent {
  event1: CalendarEvent;
  event2: CalendarEvent;
}
/**
 *
 * @param event1 First event to compare
 * @param event2 Second event to compare
 * @returns A boolean whether the two events are the same. It will not take week into account, only hour and day
 */
export function shouldMergeEvents({ event1, event2 }: ShouldMergeEvent) {
  return (
    hasSameTime(
      { beginDate: event1.start, endDate: event1.end },
      { beginDate: event2.start, endDate: event2.end }
    ) &&
    event1.data.name === event2.data.name &&
    event1.data.allocationObjectId === event2.data.allocationObjectId
  );
}

interface SetEventTimes {
  events: CalendarEvent[];
  week: number;
  year: number;
}
/**
 *
 * @param events Events
 * @param The week the event should be set to
 * @param The year the event should be set to
 * @returns A event list with a begin and end value that matches time parameters.
 */
export function setEventTimes({ year, week, events }: SetEventTimes) {
  return events.map((event) => {
    const startDay = dayjs(event.start).day();
    const endDay = dayjs(event.end).day();
    const start = dayjs(event.start)
      .week(week)
      .year(year)
      .day(startDay)
      .toDate();
    const end = dayjs(event.end).week(week).year(year).day(endDay).toDate();

    return {
      ...event,
      start,
      end,
    };
  });
}

interface StartEndDate {
  beginDate: Date;
  endDate: Date;
}
/**
 *
 * @param a First start end time object
 * @param b Second start end time object
 * @returns A boolean based on day and hour are the same of the time objects. It also checks for the duration to be the same
 */
function hasSameTime(a: StartEndDate, b: StartEndDate) {
  return (
    dayjs(a.beginDate).format("dd HH:mm") ===
      dayjs(b.beginDate).format("dd HH:mm") &&
    dayjs(a.endDate).format("dd HH:mm") ===
      dayjs(b.endDate).format("dd HH:mm") &&
    dayjs(a.beginDate).diff(a.endDate) === dayjs(b.beginDate).diff(b.endDate)
  );
}

/**
 * Suppress specific error from which comes from react-big-calendar
 */
export function errorSuppression(originalError: Console["error"]) {
  return {
    suppressError: () => {
      /* eslint-disable-next-line no-console */
      console.error = function (...args) {
        if (
          args[0].includes?.(
            "Using UNSAFE_componentWillReceiveProps in strict mode"
          )
        ) {
          return;
        }
        originalError.apply(console, args);
      };
    },
    unSuppressError: () => {
      /* eslint-disable-next-line no-console */
      console.error = originalError;
    },
  };
}

type CreateEventsOverlappingProps = {
  events: CalendarEvent[];
};

/**
 * @param events - A list of events that checks if any overlaps
 * @returns A record with event id as key and a list of events that overlaps with the key
 * example - {id1: [id2, id3], id2: [id1], id3: [id1]}
 */
export function createEventsOverlapping({
  events,
}: CreateEventsOverlappingProps) {
  return events.reduce<UnsafeRecord<string, CalendarEvent[]>>(
    (eventsOverlapping, eventToCheck, index, eventsArray) => {
      // Last index should already have all overlaps.
      const checkFromIndex = index + 1;
      if (checkFromIndex === eventsArray.length) return eventsOverlapping;

      const remainderToCheck = eventsArray.slice(checkFromIndex);
      remainderToCheck.forEach((event) => {
        if (eventsHasOverlap(eventToCheck, event)) {
          const currentEventIds1 = eventsOverlapping[eventToCheck.id] ?? [];
          eventsOverlapping[eventToCheck.id] = [...currentEventIds1, event];

          const currentEventIds2 = eventsOverlapping[event.id] ?? [];
          eventsOverlapping[event.id] = [...currentEventIds2, eventToCheck];
        }
      });

      return eventsOverlapping;
    },
    {}
  );
}

function eventsHasOverlap(event1: CalendarEvent, event2: CalendarEvent) {
  return (
    dayjs(event1.start).isBefore(event2.end) &&
    dayjs(event1.end).isAfter(event2.start)
  );
}
