// These should be in the types library once it is ready.
// Copied from this pr https://github.com/timeedit/types/pull/30
import { z } from "zod";
import { DateTransform, ExtractIdTransform } from "./transforms";
import {
  ZAppIdOptional,
  ZAppIdRequired,
  ZAppProperties,
  ZApplicationObjectTypeGroup,
  ZOrganizationVisibilityOptional,
  // ZObjectTypeMappingStatusesRequired,
  // ZPersonalDataCheck,
  // ZPersonalInformation,
  // ZPreferencesFilterRequired,
  // ZPropertyOptional,
  ZRegionOptional,
  ZTokenTypeOptional,
  // ZUserVisibility,
} from "./enums";
import { EAppId, EFieldType } from "@timeedit/types/lib/enums";
import { isPositiveNumber } from "../typechecks";
import { ZUnsafeRecord } from "./utils";

// reservation.enum.ts
const reservationStatuses = [
  "UNKNOWN",
  "A_RESERVATION",
  "A_AVAILABILITY",
  "A_INFO",
  "B_COMPLETE",
  "B_INCOMPLETE",
  "C_CONFIRMED",
  "C_PRELIMINARY",
  "C_PLANNED",
  "C_REQUESTED",
  "C_REJECTED",
  "D_TIME",
  "D_WAITING_LIST",
] as const;

export const ReservationStatus = z.enum(reservationStatuses);
export type ReservationStatus = z.infer<typeof ReservationStatus>;

// attributesAndValuesPair.type.ts
export const TAttributesAndValuesPair = z
  .tuple([z.array(z.string()), z.array(z.string())])
  .optional();
export type TAttributesAndValuesPair = z.infer<typeof TAttributesAndValuesPair>;

export const TAttributeValueArrayPair = z.tuple([z.string(), z.string()]);
export type TAttributeValueArrayPair = z.infer<typeof TAttributeValueArrayPair>;

// dateHistory.type.ts
export const THistory = z.object({
  modified: z.number().optional(),
  modifiedBy: ExtractIdTransform?.optional(),
});
export type THistory = z.infer<typeof THistory>;

// language.types.ts
export const TAltDesignations = z.object({
  language: z.string(),
  name: z.string(),
  description: z.string(),
});
export type TAltDesignations = z.infer<typeof TAltDesignations>;

// field.type.ts

const fieldTypes = [
  EFieldType.TEXT,
  EFieldType.SIGNATURE,
  EFieldType.EMAIL,
  EFieldType.URL,
  EFieldType.TELEPHONE,
  EFieldType.CATEGORY,
  EFieldType.COMMENT,
  EFieldType.INTEGER,
  EFieldType.LENGTH,
  EFieldType.CHECKBOX,
  EFieldType.REFERENCE,
  EFieldType.NON_SEARCHABLE_TEXT,
  EFieldType.NON_SEARCHABLE_REFERENCE,
  "",
] satisfies [EFieldType, ...EFieldType[], ""];

export const TFieldType = z.enum(fieldTypes);
export type TFieldType = z.infer<typeof TFieldType>;

export const TFindFieldsBody = z
  .object({
    sort: z.string().optional(),
    fieldType: TFieldType.default("").optional(),
    typeId: z.number().optional(),
    searchText: z.string().optional(),
    ids: z.array(z.number()).optional(),
    extIds: z.array(z.string()).optional(),
    page: z.number().optional(),
    limit: z.number().optional(),
  })
  .optional()
  .transform((schema) => {
    if (!schema) return schema;
    if (schema.typeId === 0 && Object.keys(schema).length === 1) {
      delete schema.typeId;
    }
    return schema;
  });

export const TFindFieldsRequest = z.object({
  body: TFindFieldsBody,
});

export type TFindFieldsBody = z.infer<typeof TFindFieldsBody>;
export type TFindFieldsRequest = z.infer<typeof TFindFieldsRequest>;

export const TField = z
  .object({
    id: z.number(),
    extId: z.string(),
    name: z.string(),
    // description: z.string(),
    // altDesignations: z.array(TAltDesignations),
    // documentation: z.string(),
    // history: z.array(THistory),
    fieldType: TFieldType.default("").optional(),
    // personalData: ZPersonalDataCheck,
    // filter: z.union([ZPreferencesFilterRequired, z.string()]).optional(),
    // length: z.number().optional(),
    // min: z.number().optional(),
    // max: z.number().optional(),
    // sumType: z.number().optional(),
    // topLevelType: z.number().optional(),
    // titleType: z.number().optional(),
    refSeparator: z.string().optional(),
    // searchableInReservationList: z.boolean().optional(),
    // reservationTextField: z.boolean().optional(),
    // mandatory: z.boolean().optional(),
    // unique: z.boolean().optional(),
    // multiple: z.boolean().optional(),
    // editable: z.boolean().optional(),
    searchable: z.boolean(),
    // listable: z.boolean(),
    // primary: z.boolean(),
    // sortable: z.boolean(),
    // virtualCategory: z.string().optional(),
    // abstractCategory: z.string().optional(),
    defaultValue: z.string().optional(),
    checkSum: z.number().optional(),
    categories: z.array(z.string()).optional(),
    referenceFields: z.array(z.number()),
    types: z.array(z.number()),
    // reservationTemplates: z.array(z.number()),
    // organizations: z.array(z.string()),
  })
  .passthrough();
export type TField = z.infer<typeof TField>;

export const TFields = RestApiMultipleResultsSchema(TField);
export type TFields = z.infer<typeof TFields>;

// object.type.ts

export const TTEFieldValue = z.object({
  fieldId: z.number(),
  values: z.array(z.string()),
});
export type TTEFieldValue = z.infer<typeof TTEFieldValue>;

export const TDateRange = z.object({
  objectId: z.number(),
  start: z.number(),
  end: z.number(),
});
export type TDateRange = z.infer<typeof TDateRange>;

export const TDateRangeRelations = z.object({
  start: z.number(),
  end: z.number(),
  objectIds: z.array(z.number()),
});
export type TDateRangeRelations = z.infer<typeof TDateRangeRelations>;

export const TAvailabilityRelations = z.object({
  availabilityTypeId: z.number(),
  objectIds: z.array(z.number()),
});
export type TAvailabilityRelations = z.infer<typeof TAvailabilityRelations>;

export const TTEFindObjectsBody = z
  .object({
    sort: z.string().optional(),
    objectStatus: z.string().optional(), // Type up the options
    page: z.number().optional(),
    limit: z.number().optional(),
    virtual: z.string().optional(), // Type up the options
    abstract: z.string().optional(), // Type up the options
    active: z.string().optional(), // Type up the options
    typeId: z.number().optional(),
    ids: z.array(z.number()).optional(),
    extIds: z.array(z.string()).optional(),
    excludeIds: z.array(z.number()).optional(),
    excludeExtIds: z.array(z.string()).optional(),
    modified: z
      .object({
        beginModified: z.number(),
        endModified: z.number(),
      })
      .optional(),
    fields: z
      .object({
        exactSearchFieldIds: z.array(z.number()),
        generalSearchFieldIds: z.array(z.number()),
        searchText: z.string().optional(),
      })
      .optional(),
  })
  .optional();

export const TTEFindObjectsRequest = z.object({
  body: TTEFindObjectsBody,
});

export type TTEFindObjectsBody = z.infer<typeof TTEFindObjectsBody>;
export type TTEFindObjectsRequest = z.infer<typeof TTEFindObjectsRequest>;

export const TTEObject = z
  .object({
    id: z.number(),
    extId: z.string(),
    created: z.number(),
    // createdBy: z.string(),
    // history: z.union([z.array(THistory), THistory]),
    // property: ZPropertyOptional,
    // personalInformation: ZPersonalInformation,
    fields: z.array(TTEFieldValue),
    // organizations: z.array(z.string()),
    members: z.array(TDateRange),
    types: z.array(z.number()),
    relations: z.array(TDateRangeRelations),
    // availabilityRelations: z.array(TAvailabilityRelations),
    // optionalRelations: z.array(z.number()),
    active: z.boolean(),
    virtual: z.boolean(),
    abstract: z.boolean(),
    // color: z.string(),
  })
  .passthrough();
export type TTEObject = z.infer<typeof TTEObject>;

export const TTEObjects = RestApiMultipleResultsSchema(TTEObject);
export type TTEObjects = z.infer<typeof TTEObjects>;

// zod.util.ts

export function RestApiMultipleResultsSchema<ResultType extends z.ZodTypeAny>(
  resultType: ResultType
) {
  return z.object({
    limit: z.number().optional(),
    page: z.number().optional(),
    results: z.array(resultType).optional(),
    totalPages: z.number().optional(),
    totalResults: z.number().optional(),
  });
}

export const TTEObjectPut = z.object({
  id: z.number(),
  extId: z.string(),
  status: z.number(),
});
export type TTEObjectPut = z.infer<typeof TTEObjectPut>;

export function RestApiMultipleResultsSchemaPut<
  ResultType extends z.ZodTypeAny,
>(resultType: ResultType) {
  return RestApiMultipleResultsSchema(resultType).extend({
    failed: z.number().optional().default(0),
    success: z.number().optional().default(0),
  });
}
export const TTEObjectsPut = RestApiMultipleResultsSchemaPut(TTEObjectPut);
export type TTEObjectsPut = z.infer<typeof TTEObjectsPut>;

// organization.type.ts

export const TOrganization = z
  .object({
    id: z.string(),
    name: z.string().optional().default(""),
    customerSignature: z.string().optional().default(""),
    //customerLogo: z.string().optional().nullable().default(""),
    // teServerUrl: z.string().optional().default(""),
    public: z.boolean().optional().default(false),
    enabledProducts: z
      .array(ZAppIdOptional)
      .transform((input) => input.filter(Boolean) as EAppId[])
      .optional()
      .default([]),
    // Org type is not trustworthy.
    // type: ZOrganizationTypeRequired,
    rootUserPermissions: z
      .array(TAttributeValueArrayPair.or(z.string()))
      .optional()
      .default([]),
    rootUserStandardOrganizationNode: z.string().optional().default(""),
    region: ZRegionOptional,
    visibility: ZOrganizationVisibilityOptional,
    // createdAt: z.number().optional().default(0),
    // updatedAt: z.number().optional().default(0),
    // inactiveSince: z.number().optional().nullable().default(null),
    inactive: z.boolean().optional().default(false),
  })
  .passthrough();
export type TOrganization = z.infer<typeof TOrganization>;

export const TOrganizations = RestApiMultipleResultsSchema(TOrganization);
export type TOrganizations = z.infer<typeof TOrganizations>;

// reservation.type.ts

export const TReservationsSearchObject = z.object({
  objectId: z.number(),
  typeId: z.number(),
});

export type TReservationsSearchObject = z.infer<
  typeof TReservationsSearchObject
>;

export const TFindReservationsBody = z.object({
  sort: z.string().optional(),
  page: z.number().optional(),
  limit: z.number().optional(),
  modified: z
    .object({
      beginModified: z.number().optional(),
      endModified: z.number().optional(),
    })
    .optional(),
  date: z
    .object({
      startDate: z.number().optional(),
      endDate: z.number().optional(),
    })
    .optional(),
  searchObjects: z.array(TReservationsSearchObject).optional(),
  types: z.array(z.number()).optional(),
  fields: z
    .object({
      exactSearchFieldIds: z.array(z.number()),
      generalSearchFieldIds: z.array(z.number()),
      searchText: z.string().optional(),
    })
    .optional(),
  reservationStatus: z.array(z.string()).optional(),
  mineOnly: z.boolean().optional(),
  ids: z.array(z.number()).optional(),
  extIds: z.array(z.string()).optional(),
  typeId: z.number().optional(),
});
export type TFindReservationsBody = z.infer<typeof TFindReservationsBody>;

export const TFindReservationsRequest = z.object({
  body: TFindReservationsBody,
});
export type TFindReservationsRequest = z.infer<typeof TFindReservationsRequest>;

export const TReservation = z
  .object({
    id: z.number(),
    extId: z.string().optional(),
    // organizations: z.array(z.string()).optional(),
    // history: z
    // .object({
    // modified: z.number().optional(),
    // modifiedBy: z.string().optional(),
    // })
    // .optional(),
    // created: z.number().optional(),
    // createdBy: z.string().optional(),
    begin: z.number().optional(),
    end: z.number().optional(),
    // originalReservation: z.number().optional(),
    // autoEngineId: z.string().optional(),
    // length: z.number().optional(),
    objects: z
      .array(
        z.object({
          objectId: z.number(),
          typeId: z.number(),
        })
      )
      .optional(),
    fields: z
      .array(
        z.object({
          fieldId: z.number(),
          values: z.array(z.string()),
        })
      )
      .optional(),
    status: z.array(ReservationStatus).optional(),
    // tags: z.array(z.string()).optional(),
  })
  .passthrough();
export type TReservation = z.infer<typeof TReservation>;

export const TReservations = RestApiMultipleResultsSchema(TReservation);
export type TReservations = z.infer<typeof TReservations>;

// token.type.ts

export const ITEToken = z.object({
  region: ZRegionOptional,
  // Mandatory identifiers
  id: z.string(), // User id
  type: ZTokenTypeOptional,
  customerSignature: z.string().nullable().optional(),
  organizationId: z.string().nullable().optional(),
  username: z.string().optional(),
  email: z.string().nullable().optional(),
  // Permissions
  appPermissions: z.array(z.string()).optional().default([]),
  scopes: z.array(z.string()),
  organizationsAndRoles: z.array(TAttributeValueArrayPair).optional(),
  // Token stuff
  iat: z.number().optional(),
  exp: z.number().optional(),
});

export type ITEToken = z.infer<typeof ITEToken>;

// type.types.ts

export const TFindTypesBody = z
  .object({
    sort: z.string().optional(),
    searchText: z.string().optional(),
    ids: z.array(z.number()).optional(),
    extIds: z.array(z.string()).optional(),
    reservationTemplates: z.number().optional(),
    page: z.number().optional(),
    limit: z.number().optional(),
  })
  .optional();

export type TFindTypesBody = z.infer<typeof TFindTypesBody>;
export const TFindTypesRequest = z.object({
  body: TFindTypesBody,
});
export type TFindTypesRequest = z.infer<typeof TFindTypesRequest>;

export const TType = z
  .object({
    id: z.number(),
    extId: z.string(),
    name: z.string(),
    // description: z.string(),
    // documentation: z.string(),
    // history: z.array(THistory),
    // altDesignations: z.array(TAltDesignations),
    active: z.boolean(),
    // personalData: z.boolean(),
    // allowStandardObjects: z.boolean(),
    // allowAbstractObjects: z.boolean(),
    // allowVirtualObjects: z.boolean(),
    // personType: z.boolean(),
    // locationType: z.boolean(),
    // aliasType: z.number(),
    // externalOwner: z.string(),
    // subTypes: z.array(z.number()).optional(),
    // fields: z.array(z.number()).optional(),
    // parentFields: z.array(z.number()).optional(),
    // reservationTemplates: z.array(z.number()).optional(),
    // organizations: z.array(z.string()).optional(),
  })
  .passthrough();
export type TType = z.infer<typeof TType>;

export const TTypes = RestApiMultipleResultsSchema(TType);
export type TTypes = z.infer<typeof TTypes>;

// user.type.ts

export const TLogins = z.object({
  login: z.number().optional(),
  appId: ZAppIdRequired,
});

export type TLogins = z.infer<typeof TLogins>;

export const TTEUser = z
  .object({
    id: z.string().nullable().optional(),
    inactiveSince: z.number().nullable().optional(),

    // DESCRIPTIVES
    organizationId: z.string().nullable().optional(),
    username: z.string().optional(),
    email: z.string().nullable().optional(),
    firstName: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),

    // LEGACY INFORMATION
    // legacyId: z.string().nullable().optional(),

    // CONFIG SETTINGS
    // language: z.string().nullable().optional(),
    // dateFormat: z.string().nullable().optional(),
    // displayTimezones: z.array(z.string()).optional(),
    // timezone: z.string().nullable().optional(),
    // visibility: ZUserVisibility.optional(),
    // personalData: z.boolean().optional(),
    // description: z.string().nullable().optional(),

    // ORGANIZATIONS AND PERMISSIONS
    // standardOrganization: z.string().nullable().optional(), // Only leaves allowed
    // ** NOTE ** organizationAndRoles can fail validation how it looks below. It can have a tuple of value null
    // organizationsAndRoles: z.array(TAttributeValueArrayPair).optional(),
    scopes: z.array(z.string()),
    appPermissions: z.array(z.string()).optional(),

    // USER OBJECTS
    userObjects: z.array(z.string()).optional(),

    // AUTH CONFIG
    // authConfigId: z.string().optional(),

    // USAGE STATISTICS
    // logins: z.array(TLogins).optional(),
    // createdBy: z.string().nullable().optional(),
    // history: z.array(THistory),
    // lockedForExternalAuthentication: z.boolean().optional(),
    // createdAt: z.number().optional(),
    // updatedAt: z.number().optional(),
  })
  .passthrough();
export type TTEUser = z.infer<typeof TTEUser>;
export const TTEUsers = RestApiMultipleResultsSchema(TTEUser);
export type TTEUsers = z.infer<typeof TTEUsers>;

// mapping.type.ts

export const IFieldMapping = z.object({
  isSearchable: z.boolean().optional(),
  // appFieldName: z.string(),
  appProperty: ZAppProperties,
  fieldExtId: z.string().optional().nullable(),
  fieldId: z.number().optional().nullable(),
  // fieldLabel: z.string(),
  // allocationFieldFromObject: z.string().optional().nullable(),
});
export type IFieldMapping = z.infer<typeof IFieldMapping>;

export const ITypeMapping = z
  .object({
    // applicationObjectTypeName: z.string(),
    applicationObjectTypeGroup: ZApplicationObjectTypeGroup,
    // applicationObjectTypeLabel: z.string(),
    objectTypeExtId: z.string().optional().nullable(),
    objectTypeId: z.number().optional().nullable(),
    // labelField: z.string().optional().nullable(),
    // secondaryLabelField: z.string().optional().nullable(),
    fields: z.array(IFieldMapping),
    // status: ZObjectTypeMappingStatusesRequired,
  })
  .passthrough();
export type ITypeMapping = z.infer<typeof ITypeMapping>;

export const IMapping = z.object({
  appId: ZAppIdRequired,
  objectTypes: z.array(ITypeMapping),
});
export type IMapping = z.infer<typeof IMapping>;

export const ParsedDateTimeInfo = (timezone: string) =>
  z.object({
    currentDateTime: DateTransform(timezone),
    offsetDateTime: DateTransform(timezone),
  });

export type ParsedDateTimeInfo = z.infer<ReturnType<typeof ParsedDateTimeInfo>>;

export const TimeZoneObject = z
  .object({
    id: z.number(),
    extId: z.string(),
    name: z.string(),
    identifiers: z.array(z.string()).optional(),
    // daylightSavingsTime: z.string(),
    isDefault: z.boolean(),
    // offset: z.string(),
    timeZone: z.string(),
  })
  .passthrough();

export type TimeZoneObject = z.infer<typeof TimeZoneObject>;

export const TTimeZones = RestApiMultipleResultsSchema(TimeZoneObject);

export type TTimeZones = z.infer<typeof TTimeZones>;

export const ViewLink = z.object({
  id: z.string().optional(),
  viewLinkSubmitter: z.string().optional(),
  viewerEntry: z.string().optional(),
  viewerPath: z.string().optional(),
  viewerPage: z.string().optional(),
  commentField: z.string().optional(),
  currentPeriod: z.string().optional(),
});
export type ViewLink = z.infer<typeof ViewLink>;

const DateRange = z.object({
  startDate: z.number().optional(),
  endDate: z.number().optional(),
});
export type DateRange = z.infer<typeof DateRange>;

const Range = z.object({
  start: z.number(),
  end: z.number(),
});
export type Range = z.infer<typeof Range>;
export const FieldValues = ZUnsafeRecord(z.number(), z.array(z.string()));
export type FieldValues = z.infer<typeof FieldValues>;
export const CurrentPeriod = z.object({
  range: Range.optional(),
  fields: FieldValues.optional(),
});
export type CurrentPeriod = z.infer<typeof CurrentPeriod>;

export const IdName = z.object({ id: z.number(), name: z.string() });
export type IdName = z.infer<typeof IdName>;

export const ViewLinkParsed = ViewLink.extend({
  current: CurrentPeriod,
  dateRange: DateRange.optional(),
  currentFieldIds: z.array(z.number()),
});
export type ViewLinkParsed = z.infer<typeof ViewLinkParsed>;

export class IntRange {
  start: number;
  end: number;
  constructor(start: number, end: number) {
    this.start = start;
    this.end = end;
  }

  isValid() {
    return (
      isPositiveNumber(this.start) &&
      isPositiveNumber(this.end) &&
      this.start <= this.end
    );
  }

  includes(value: number) {
    return !this.isBefore(value) && !this.isAfter(value);
  }

  isBefore(value: number) {
    return value < this.start;
  }

  isAfter(value: number) {
    return value > this.end;
  }
}
