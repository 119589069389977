import { ErrorName } from "../types/errors";
import { ZErrorName } from "../zod";
import { isDefined, isRecord, isString } from "./primitives";

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage<T>(
  error: unknown
): error is T & { message: string } {
  return (
    isRecord(error) &&
    Object.prototype.hasOwnProperty.call(error, "message") &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'logId' property
 *
 */
export function isErrorWithLogId<T>(
  error: unknown
): error is T & { logId: string } {
  return isRecord(error) && "logId" in error && isDefined(error.logId);
}

/**
 * Type predicate to narrow an unknown error to error with a stack trace
 *
 */
export function isErrorWithStack<T>(
  error: unknown
): error is T & { stack: string } {
  return isRecord(error) && "stack" in error;
}

/**
 * Type predicate to narrow an unknown error to error with an 'errorName' property
 *
 */
export function isErrorWithErrorName<T>(
  error: unknown
): error is T & { errorName: ErrorName } {
  return (
    isRecord(error) &&
    "errorName" in error &&
    isString(error.errorName) &&
    ZErrorName.safeParse(error.errorName).success
  );
}
