import {
  Button,
  PopulateCacheControls,
  Text,
} from "@timeedit/registration-components";
import {
  ErrorName,
  RegistrationError,
  getErrorName,
  translations,
} from "@timeedit/registration-shared";
import "./cache.scss";
import {
  useFetchClearCacheMutation,
  useFetchPopulateUserObjectsMutation,
  usePopulateCacheMutation,
  usePopulateCacheStatusQuery,
} from "../../redux/api/cache";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function CachePage() {
  const [triggerPopulateUserObjects, populateUserObjectResult] =
    useFetchPopulateUserObjectsMutation();
  const [triggerClearCache, clearCacheResult] = useFetchClearCacheMutation();

  if (populateUserObjectResult.isError) {
    throw new RegistrationError({
      errors: [populateUserObjectResult.error],
      attemptRecovery: triggerPopulateUserObjects,
    });
  }

  if (clearCacheResult.isError) {
    throw new RegistrationError({
      errors: [clearCacheResult.error],
      attemptRecovery: triggerClearCache,
    });
  }

  return (
    <div className="cache__container">
      <div className="cache__container cache__container__user-objects">
        <Button
          loading={populateUserObjectResult.isLoading}
          onClick={() => triggerPopulateUserObjects()}
        >
          Populate user objects
        </Button>
        {populateUserObjectResult.isSuccess && (
          <Text
            className="populate-cache__container__successText"
            type="success"
          >
            Successfully fetched user objects!
          </Text>
        )}
      </div>
      <div className="cache__container cache__container__clear">
        <Button
          loading={clearCacheResult.isLoading}
          onClick={() => triggerClearCache()}
        >
          Clear cache
        </Button>
        {clearCacheResult.isSuccess && (
          <Text className="cache__container__successText" type="success">
            Successfully cleared cache!
          </Text>
        )}
      </div>
      <PopulateCache />
    </div>
  );
}

function PopulateCache() {
  const [populateCache, populateCacheResult] = usePopulateCacheMutation();
  const populateCacheStatus = usePopulateCacheStatusQuery();
  const navigate = useNavigate();

  const retryPopulateCache = useCallback(() => {
    populateCache({ force: true });
    navigate("/cache");
  }, [populateCache, navigate]);

  if (
    populateCacheResult.isError &&
    getErrorName(populateCacheResult.error) !== ErrorName.PopulateCacheBusyError
  ) {
    throw new RegistrationError({
      errors: [populateCacheResult.error],
      attemptRecovery: retryPopulateCache,
    });
  }

  if (populateCacheStatus.isError) {
    throw new RegistrationError({
      errors: [populateCacheStatus.error],
      attemptRecovery: retryPopulateCache,
    });
  }

  return (
    <PopulateCacheControls
      status={populateCacheStatus.data?.status}
      onClick={populateCache}
      translations={translations}
    />
  );
}
