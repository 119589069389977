import "./populateCacheControls.scss";
import { Tag } from "@timeedit/ui-components";
import {
  PopulateCacheStatus,
  exhaustiveMatchingGuard,
} from "@timeedit/registration-shared";
import {
  CheckOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button } from "../Button";
import { ButtonProps } from "antd";

interface Translations {
  populateCache: string;
}

interface PopulateCacheControlsProps extends ButtonProps {
  translations: Translations;
  status?: PopulateCacheStatus;
  onClick: () => void;
}

export function PopulateCacheControls({
  translations,
  status,
  onClick,
  ...buttonProps
}: PopulateCacheControlsProps) {
  return (
    <div className="populate-cache-controls__container">
      <Button
        {...buttonProps}
        disabled={status === "pending" || buttonProps.disabled}
        onClick={onClick}
      >
        {translations.populateCache}
      </Button>
      <PopulateCacheTag status={status} />
    </div>
  );
}

interface PopulateCacheTagProps {
  status: PopulateCacheStatus | undefined;
}
function PopulateCacheTag({ status }: PopulateCacheTagProps) {
  switch (status) {
    case "uninitialized":
    case undefined: {
      return null;
    }
    case "pending": {
      return (
        <Tag color="processing" icon={<SyncOutlined />}>
          {status}
        </Tag>
      );
    }
    case "completed": {
      return (
        <Tag color="success" icon={<CheckOutlined />}>
          {status}
        </Tag>
      );
    }
    case "failed": {
      return (
        <Tag color="error" icon={<CloseCircleOutlined />}>
          {status}
        </Tag>
      );
    }
    default: {
      return exhaustiveMatchingGuard(status);
    }
  }
}
