import { TDateRange, TTEFieldValue } from "../zod";
import {
  AllocateOptimizeJobsResult,
  OptimizerJobSchema,
  OptimizerMeta,
} from "../zod/optimizer";
import { z } from "zod";

export type AllocateOptimizeJobsList = {
  jobs: AllocateOptimizeJobsResult[];
  total: number;
};

export type ListOptimizerJobs = {
  jobs: OptimizerJobSchema[];
  total: number;
};

export type OptimizerDedication = {
  capacity: number;
  property: string;
};

export type OptimizerAllocationGroupSSG = {
  id: number;
  capacity: number;
  dedications: OptimizerDedication[];
};

export type OptimizerAllocationGroup = {
  id: number;
  studentSetGroups: OptimizerAllocationGroupSSG[];
};

export type OptimizerStudentSSG = {
  id: number;
  score: number;
};

export type OptimizerStudent = {
  id: number;
  properties: string[];
  studentSetGroups: OptimizerStudentSSG[][];
};

export type OptimizerCourse = {
  id: number;
  allocationGroups: OptimizerAllocationGroup[];
};

export type OptimizerProblem = {
  id: string;
  students: OptimizerStudent[];
  courses: OptimizerCourse[];
  objects: OptimizerObject[];
  reservations: OptimizerReservation[];
};

export type OptimizerLinkedGroups = {
  groups: number[];
};

export type OptimizerModel = {
  linkedGroups: OptimizerLinkedGroups[];
};

export type AllocationOptimizerInputSchema = {
  runType: "Allocation";
  problem: OptimizerProblem;
  meta: OptimizerMeta;
  model: OptimizerModel;
};

export type ProposalAllocationResultDataSource = {
  key: string;
  course: string;
  activityType: string;
  group: string;
  student: string;
  issueType: string;
  manageStudentLink: string;
  activityTypeId?: number;
};

export const OptimizerObject = z.object({
  id: z.number(),
  fields: z.array(TTEFieldValue),
  members: z.array(TDateRange),
});
export type OptimizerObject = z.infer<typeof OptimizerObject>;

export const OptimizerReservation = z.object({
  id: z.number(),
  begin: z.number().optional(),
  end: z.number().optional(),
  objects: z.array(
    z.object({
      objectId: z.number(),
      typeId: z.number(),
    })
  ),
  fields: z.array(
    z.object({
      fieldId: z.number(),
      values: z.array(z.string()),
    })
  ),
});
export type OptimizerReservation = z.infer<typeof OptimizerReservation>;
