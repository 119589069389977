export enum ErrorName {
  Error = "Error",
  ValidationError = "ValidationError",
  BadRequestError = "BadRequestError",
  NotFoundError = "NotFoundError",
  StudentSetGroupConfigurationError = "StudentSetGroupConfigurationError",
  PopulateCacheBusyError = "PopulateCacheBusyError",
  NoStudentObjectError = "NoStudentObjectError",
  PacemakerFetchError = "PacemakerFetchError",
  PacemakerResponseError = "PacemakerResponseError",
  ExternalServiceError = "ExternalServiceError",
  RegistrationApiError = "RegistrationApiError",
  HeavyLoadError = "HeavyLoadError",
  RegistrationError = "RegistrationError",
  MaximumMembershipReachedError = "MaximumMembershipReachedError",
  MaximumMembershipReachedByTypeError = "MaximumMembershipReachedByTypeError",
}

// Te server error codes:
// https://github.com/timeedit/teserver/blob/main/source/api/API_ResponseMessageName.cpp#L157
export const TeServerErrorCodes = {
  [ErrorName.MaximumMembershipReachedError]: -2306,
  [ErrorName.MaximumMembershipReachedByTypeError]: -2307,
} as const;

export type LogLevel = "trace" | "debug" | "info" | "warn" | "error" | "fatal";
