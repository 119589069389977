import { z } from "zod";
import { partialNotUndefined } from "./utils";

export const POPULATE_CACHE_NAME = "Registration - Populate Cache" as const;
const populateCacheStatuses = [
  "uninitialized",
  "pending",
  "completed",
  "failed",
] as const;

export const PopulateCacheStatus = z.enum(populateCacheStatuses);
export type PopulateCacheStatus = z.infer<typeof PopulateCacheStatus>;

export const PopulateCacheJobSchemaBase = z.object({
  name: z.literal(POPULATE_CACHE_NAME),
  jobId: z.string(),
  lastRunAt: z.number(),
  lastFinishedAt: z.number().nullable().optional(),
  status: PopulateCacheStatus,
});

export const PopulateCacheError = z.object({
  status: z.number(),
  name: z.string(),
  message: z.string(),
});

export const PopulateCacheJobSchema = z.discriminatedUnion("status", [
  PopulateCacheJobSchemaBase.extend({ status: z.literal("uninitialized") }),
  PopulateCacheJobSchemaBase.extend({ status: z.literal("pending") }),
  PopulateCacheJobSchemaBase.extend({ status: z.literal("completed") }),
  PopulateCacheJobSchemaBase.extend({
    status: z.literal("failed"),
    error: PopulateCacheError,
  }),
]);

export const PartialPopulateCacheJobSchema = partialNotUndefined(
  z.discriminatedUnion("status", [
    PopulateCacheJobSchemaBase.partial().extend({
      jobId: z.string(),
      status: z.literal("pending"),
    }),
    PopulateCacheJobSchemaBase.partial().extend({
      jobId: z.string(),
      status: z.literal("completed"),
    }),
    PopulateCacheJobSchemaBase.partial().extend({
      jobId: z.string(),
      status: z.literal("failed"),
      error: PopulateCacheError,
    }),
  ])
);

export type PopulateCacheJobSchema = z.infer<typeof PopulateCacheJobSchema>;
export type PartialPopulateCacheJobSchema = z.infer<
  typeof PartialPopulateCacheJobSchema
>;
