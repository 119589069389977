import { z } from "zod";
import { TTEFieldValue, TTEObject } from "./types";
import { FilterValueTransform, IntegerTransform } from "./transforms";
import { ZUnsafeRecord } from "./utils";
import { ZErrorName } from "./enums";

//-----------------------------------------------------------------------------
// --> Course
//-----------------------------------------------------------------------------

// Allocate specific course model type
export const AllocateCourse = z.object({
  id: z.number(),
  incompleteStudents: z.array(z.number()).optional(),
  enrolledStudents: z.array(z.number()),
  object: TTEObject,
});
export type AllocateCourse = z.infer<typeof AllocateCourse>;

//-----------------------------------------------------------------------------
// --> Distribution
//-----------------------------------------------------------------------------
export type AddStudentsToTracksOverrides = z.infer<
  typeof AddStudentsToTracksOverrides
>;

export const FailsWithTracks = z.object({
  conflict: z.array(z.number()),
  size: z.array(z.number()),
  buffer: z.array(z.number()),
  dedicatedSize: z.array(z.number()),
  doubleBooked: z.array(z.number()),
  dedicated: z.array(z.number()),
  hidden: z.array(z.number()),
});
export type FailsWithTracks = z.infer<typeof FailsWithTracks>;

export const FailsWithMessage = z.object({
  systemError: ZUnsafeRecord(
    z.string(),
    z
      .object({ message: z.string(), code: z.number(), name: ZErrorName })
      .or(z.undefined())
  ),
});
export type FailsWithMessage = z.infer<typeof FailsWithMessage>;

export const FailedTypes = z.object({
  failsWithTracks: FailsWithTracks.partial().optional(),
  failsWithMessage: FailsWithMessage.partial().optional(),
  linkedTrackIds: z.array(z.number()),
});
export type FailedTypes = z.infer<typeof FailedTypes>;

export const FailedDistribution = ZUnsafeRecord(z.string(), FailedTypes);
export type FailedDistribution = z.infer<typeof FailedDistribution>;

export const TrackListIdentifiersValue = z.object({
  courseId: z.number(),
  activity: z.string(),
  activityId: z.number().optional(),
});
export type TrackListIdentifiersValue = z.infer<
  typeof TrackListIdentifiersValue
>;

export const TrackListIdentifiers = ZUnsafeRecord(
  z.string(),
  z.array(TrackListIdentifiersValue)
);
export type TrackListIdentifiers = z.infer<typeof TrackListIdentifiers>;

export const TrackListWithReasonValue = TrackListIdentifiersValue.merge(
  z.object({
    reasons: FailedTypes.partial().optional(),
  })
);
export type TrackListWithReasonValue = z.infer<typeof TrackListWithReasonValue>;

export const AddStudentsToTracksOverrides = z.object({
  buffer: z.boolean().optional(),
  doubleBooked: z.boolean().optional(),
  dedicated: z.boolean().optional(),
  hidden: z.boolean().optional(),
});

export const TrackListWithReason = ZUnsafeRecord(
  z.string(),
  z.array(TrackListWithReasonValue)
);
export type TrackListWithReason = z.infer<typeof TrackListWithReason>;

export const TrackListWithTrackValue = TrackListIdentifiersValue.merge(
  z.object({
    track: z.number(),
  })
);
export type TrackListWithTrackValue = z.infer<typeof TrackListWithTrackValue>;

export const TrackListWithTrack = ZUnsafeRecord(
  z.string(),
  z.array(TrackListWithTrackValue)
);
export type TrackListWithTrack = z.infer<typeof TrackListWithTrack>;

export const SaveStudentsResult = z.object({
  logId: z.string().optional(),
  failedGroups: TrackListWithReason,
  successGroups: TrackListWithTrack,
});
export type SaveStudentsResult = z.infer<typeof SaveStudentsResult>;

//-----------------------------------------------------------------------------
// --> Fields
//-----------------------------------------------------------------------------

export const AllocationBuffer = z.object({
  size: z.number(),
  isPercent: z.boolean(),
});
export type AllocationBuffer = z.infer<typeof AllocationBuffer>;

export const startOrEnd = ["start", "end"] as const;
export const StartOrEnd = z.enum(startOrEnd);
export type StartOrEnd = z.infer<typeof StartOrEnd>;

// Request payloads
export const TStartOrEndBatchSetRequest = z.object({
  courseIds: z.array(z.number()),
  registrationDateToSet: z.string(), // Date string in format allocateDateTimeFormat
  startOrEnd: StartOrEnd,
  writeCoursesWithRegistrationRunning: z.boolean(),
  writeCoursesWithRegistrationPeriodSet: z.boolean(),
});
export type TStartOrEndBatchSetRequest = z.infer<
  typeof TStartOrEndBatchSetRequest
>;

// Request results
export const TStartOrEndBatchSetResult = z.object({
  successFullySet: z.array(z.number()), // courseIds
  courseNotFound: z.array(z.number()), // courseIds
  noTracksFound: z.array(z.number()), // courseIds
  errorsWhileSetting: ZUnsafeRecord(
    z.string(),
    ZUnsafeRecord(z.string(), z.string())
  ), // Format: { courseId: { trackId or courseId: error } }
  excludedBecauseRegistrationRunning: z.array(z.number()), // courseIds
  excludedRegistrationPeriodSet: z.array(z.number()), // courseIds
});
export type TStartOrEndBatchSetResult = z.infer<
  typeof TStartOrEndBatchSetResult
>;

//-----------------------------------------------------------------------------
// --> Issue List
//-----------------------------------------------------------------------------

export const issues = ["overEnrollment", "noAllocation"] as const;
export const Issue = z.enum(issues);
export type Issue = z.infer<typeof Issue>;

export const TrackCourseIds = z.object({
  trackIds: z.array(z.number()),
  courseIds: z.array(z.number()),
});
export type TrackCourseIds = z.infer<typeof TrackCourseIds>;

export const Issues = z.record(Issue, TrackCourseIds);
export type Issues = z.infer<typeof Issues>;

export const ChangeRequest = z.object({
  courseId: z.number(),
  trackIds: z.array(z.number()),
});
export type ChangeRequest = z.infer<typeof ChangeRequest>;

//-----------------------------------------------------------------------------
// --> Dedicated Tracks
//-----------------------------------------------------------------------------

export const DedicatedCategory = z.object({
  id: z.number(),
  value: z.string(),
  seats: z.number(),
});
export type DedicatedCategory = z.infer<typeof DedicatedCategory>;

export const DedicatedRelation = z.object({
  id: z.number(),
  seats: z.number(),
});

export type DedicatedRelation = z.infer<typeof DedicatedRelation>;

export const DedicatedTrack = z.union([
  z.object({
    kind: z.literal("category"),
    data: z.array(DedicatedCategory),
  }),
  z.object({
    kind: z.literal("relation"),
    data: z.array(DedicatedRelation),
  }),
  z.object({
    kind: z.literal("none"),
    data: z.undefined(),
  }),
]);
export type DedicatedTrack = z.infer<typeof DedicatedTrack>;

//-----------------------------------------------------------------------------
// --> Back End
//-----------------------------------------------------------------------------

export const TTrackInCourse = z.object({
  id: z.number(),
  name: z.string(),
});
export type TTrackInCourse = z.infer<typeof TTrackInCourse>;

const TStudentInCourse = z.object({
  id: z.number(),
  fields: z.array(TTEFieldValue),
  relations: z.array(z.number()),
  trackLists: z.array(z.number()),
  tracks: z.array(z.number()),
});
export type TStudentInCourse = z.infer<typeof TStudentInCourse>;

export const TStudentsAndTracksOfCourse = z.object({
  programs: z.array(TTEObject),
  students: z.array(TStudentInCourse),
  trackLookup: ZUnsafeRecord(z.string(), TTrackInCourse),
  trackListsLookup: ZUnsafeRecord(
    z.number(),
    z.object({
      id: z.number(),
      name: z.string(),
      tracks: z.array(z.number()),
    })
  ),
});
export type TStudentsAndTracksOfCourse = z.infer<
  typeof TStudentsAndTracksOfCourse
>;

export const ExactSearch = z.object({
  fieldId: IntegerTransform,
  values: FilterValueTransform,
});
export type ExactSearch = z.infer<typeof ExactSearch>;
