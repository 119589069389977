import { z } from "zod";
import { AllocationSignal, ObjectIdsWithType } from "./common";
import { CatalogTrack } from "./registration";
import {
  AddStudentsToTracksOverrides,
  AllocateCourse,
  ExactSearch,
} from "./allocate";
import { TReservation, TTEFieldValue, TTEObject } from "./types";
import { IntegerTransform } from "./transforms";
import { ZUnsafeRecord } from "./utils";
import { AllocationResult } from "./optimizer";

export const FindReservationsBody = z.object({
  objectIds: z.array(z.number().nonnegative().int()),
  typeId: z.number().nonnegative().int(),
  useCache: z.boolean(),
});
export type FindReservationsBody = z.infer<typeof FindReservationsBody>;

//-----------------------------------------------------------------------------
// --> Load Request
//-----------------------------------------------------------------------------

export const LoadBody = z
  .object({
    ids: z.array(z.number().nonnegative().int()).optional(),
    typeId: z.number().nonnegative().int().optional(),
    useCache: z.boolean().optional(),
    limit: z.number().nonnegative().int().optional(),
    fields: z.array(z.string().or(z.number().nonnegative().int())).optional(),
    relations: ObjectIdsWithType.optional(),
    members: ObjectIdsWithType.optional(),
    text: z.string().optional(),
    category: z.array(ExactSearch).optional(),
  })
  .refine((schema) => {
    const hasNoIdsAndNoLimit = !schema.ids?.length && !schema.limit;
    return !hasNoIdsAndNoLimit;
  }, "Limit required when no ids are specified");
export type LoadBody = z.infer<typeof LoadBody>;

export const GetStaffCoursesBody = z.object({
  fields: z
    .object({
      exactSearchFields: z.array(ExactSearch).optional(),
      generalSearchFieldIds: z.array(IntegerTransform).optional(),
      searchText: z.string().optional(),
    })
    .optional(),
  idsToFilter: z.array(IntegerTransform).optional(),
  relations: ObjectIdsWithType.optional(),
  filterIncompleteStudents: z.boolean().optional(),
  limit: z.number().nonnegative().int().optional(),
});
export type GetStaffCoursesBody = z.infer<typeof GetStaffCoursesBody>;

//-----------------------------------------------------------------------------
// --> Save Request
//-----------------------------------------------------------------------------

export const SaveBody = z.array(
  z.object({ id: z.number(), fields: z.array(TTEFieldValue) })
);
export type SaveBody = z.infer<typeof SaveBody>;

//-----------------------------------------------------------------------------
// --> Allocate Request
//-----------------------------------------------------------------------------

export const conflictControlStrategies = ["partial", "none"] as const;
export const ConflictControlStrategy = z.enum(conflictControlStrategies);
export type ConflictControlStrategy = z.infer<typeof ConflictControlStrategy>;

export const StudentInfo = ZUnsafeRecord(
  z.string(),
  z.array(z.number()).or(z.undefined())
);
export type StudentInfo = z.infer<typeof StudentInfo>;

export const AllocateRequest = z.object({
  studentInfo: StudentInfo,
  conflictControl: ConflictControlStrategy,
  overrides: AddStudentsToTracksOverrides.optional(),
});
export type AllocateRequest = z.infer<typeof AllocateRequest>;

export const AllocateProposalBody = z.object({
  jobId: z.string(),
  conflictControl: ConflictControlStrategy,
  overrides: AddStudentsToTracksOverrides.optional(),
});

export type AllocateProposalBody = z.infer<typeof AllocateProposalBody>;

//-----------------------------------------------------------------------------
// --> Deallocate Request
//-----------------------------------------------------------------------------

export const DeallocateRequest = z.object({
  studentInfo: StudentInfo,
});

export type DeallocateRequest = z.infer<typeof DeallocateRequest>;

export const DeallocateProposalBody = z.object({ jobId: z.string() });
export type DeallocateProposalBody = z.infer<typeof DeallocateProposalBody>;

//-----------------------------------------------------------------------------
// --> Swap Request
//-----------------------------------------------------------------------------

export const SwapRequest = z.object({
  studentId: z.number(),
  newObjectId: z.number(),
  prevObjectIds: z.array(z.number()),
  conflictControl: ConflictControlStrategy,
  overrides: AddStudentsToTracksOverrides.optional(),
});
export type SwapRequest = z.infer<typeof SwapRequest>;

//-----------------------------------------------------------------------------
// --> ScheduleRequest
//-----------------------------------------------------------------------------

export const ScheduleRequest = z.object({
  allocationObjects: ZUnsafeRecord(z.string(), CatalogTrack),
});

export type ScheduleRequest = z.infer<typeof ScheduleRequest>;

//-----------------------------------------------------------------------------
// --> SignalsPayload
//-----------------------------------------------------------------------------

export const SignalsPayload = z.object({
  summary: z.record(AllocationSignal, z.number()),
});

export type SignalsPayload = z.infer<typeof SignalsPayload>;

//-----------------------------------------------------------------------------
// --> PacemakerSwapResponse
//-----------------------------------------------------------------------------

export const PacemakerSwapResponse = z.object({
  results: z.array(
    z.object({
      status: z.number(),
      message: z
        .object({
          status: z.number(),
          message: z.string(),
          errorCode: z.number(),
        })
        .or(z.string()),
      memberObjectId: z.number().optional(),
      groupObjectId: z.number().optional(),
      operation: z.enum(["add", "remove"]),
    })
  ),
});

export type PacemakerSwapResponse = z.infer<typeof PacemakerSwapResponse>;

/**
  Sets the sort order. If an object is passed, values allowed are `asc`, `desc`, `ascending`, `descending`, `1`, and `-1`. 
  
  sort(arg?: string | { [key: string]: SortOrder | { $meta: any } } | [string, SortOrder][] | undefined | null): this;
 */
export const SortOrder = z.union([z.literal("asc"), z.literal("desc")]);

export type SortOrder = z.infer<typeof SortOrder>;

/** 
 [['field', 'asc']]

 https://mongoosejs.com/docs/api/query.html#Query.prototype.sort()
*/
export const MongooseSort = z.array(z.tuple([z.string(), SortOrder]));

export type MongooseSort = z.infer<typeof MongooseSort>;

export const ProposalLoad = z.object({
  skip: z.string().optional(),
  limit: z.string().optional(),
  jobId: z.string().optional(),
  sort: MongooseSort.optional(),
});

export type ProposalLoad = z.infer<typeof ProposalLoad>;

//-----------------------------------------------------------------------------
// --> PopulateCacheBody
//-----------------------------------------------------------------------------

export const PopulateCacheBody = z.object({
  force: z.boolean().optional(),
});
export type PopulateCacheBody = z.infer<typeof PopulateCacheBody>;

//-----------------------------------------------------------------------------
// --> Optimize
//-----------------------------------------------------------------------------

export const GenerateProposalBody = z.union([
  z.object({ loadCoursesProps: GetStaffCoursesBody }).strict(),
  z
    .object({
      trackIds: z.array(z.number()),
    })
    .strict(),
]);
export type GenerateProposalBody = z.infer<typeof GenerateProposalBody>;

export const DownloadProposalResultBody = z.object({ jobId: z.string() });
export type DownloadProposalResultBody = z.infer<
  typeof DownloadProposalResultBody
>;

export const AllocationResultDataSourceBody = z
  .object({
    allocationResult: AllocationResult,
  })
  .required();
export type AllocationResultDataSourceBody = z.infer<
  typeof AllocationResultDataSourceBody
>;

//-----------------------------------------------------------------------------
// --> Load Allocate Data
//-----------------------------------------------------------------------------

export const ReloadAllocateDataBody = z.object({
  loadCoursesProps: GetStaffCoursesBody.optional(),
  reloadReservations: z.boolean().optional(),
  trackIds: z.array(z.number()).default([]),
});

export type ReloadAllocateDataBody = z.infer<typeof ReloadAllocateDataBody>;

export const ReloadAllocateDataResponse = z.object({
  trackObjects: z.array(TTEObject),
  coursesToUpdate: z.array(AllocateCourse),
  coursesToRemove: z.array(z.number()),
  reservations: z.array(TReservation),
  students: z.array(TTEObject),
});

export type ReloadAllocateDataResponse = z.infer<
  typeof ReloadAllocateDataResponse
>;

export const FetchAllocateDataBody = z.union([
  z.object({ loadCoursesProps: GetStaffCoursesBody }).strict(),
  z
    .object({
      trackIds: z.array(z.number()),
    })
    .strict(),
  z
    .object({
      ids: z.array(z.number()),
    })
    .strict(),
]);

export type FetchAllocateDataBody = z.infer<typeof FetchAllocateDataBody>;

export const FetchAllocateDataResponse = z.object({
  courses: z.array(AllocateCourse),
  studentsRelated: z.array(TTEObject),
  tracksRelated: z.array(TTEObject),
});

export type FetchAllocateDataResponse = z.infer<
  typeof FetchAllocateDataResponse
>;
