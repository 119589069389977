import { z } from "zod";
import { Buffer } from "buffer";
import { SaveStudentsResult, TrackListWithReason } from "./allocate";
import { partialNotUndefined } from "./utils";

export const OptimizerKpiBase = z.object({
  id: z.string(),
  name: z.string(),
  value: z.number().optional(),
  cost: z.number().optional(),
});
export type OptimizerKpiBase = z.infer<typeof OptimizerKpiBase>;

const NofFailedDecisionsKpi = z.object({
  impossible: z.number(),
  impossibleInCurrent: z.number(),
  notEnoughSpaceOnPossible: z.number(),
});
const NofFailedDecisionsKpiKeys = Object.keys(NofFailedDecisionsKpi.shape) as [
  keyof z.infer<typeof NofFailedDecisionsKpi>,
];
const NofFailedDecisionsKpiEnum = z.enum(NofFailedDecisionsKpiKeys);

const NofFailedDecisionsSchema = OptimizerKpiBase.extend({
  id: z.literal("nofFailedDecisions"),
  kpis: z.array(
    OptimizerKpiBase.extend({
      id: NofFailedDecisionsKpiEnum,
    })
  ),
});

const SolutionAnalysisKpi = z.object({
  nofAssignedDecisions: z.number(),
  nofCompleteStudents: z.number(),
  nofIncompleteStudents: z.number(),
  nofFailedDecisions: z.number(),
});
const SolutionAnalysisKpiKeys = Object.keys(SolutionAnalysisKpi.shape) as [
  keyof z.infer<typeof SolutionAnalysisKpi>,
];
const SolutionAnalysisKpiEnum = z.enum(SolutionAnalysisKpiKeys);
const SolutionAnalysisSchema = OptimizerKpiBase.extend({
  id: z.literal("solutionAnalysis"),
  kpis: z.array(
    z.union([
      NofFailedDecisionsSchema,
      OptimizerKpiBase.extend({
        id: SolutionAnalysisKpiEnum,
      }),
    ])
  ),
});

const ProblemAnalysisKpi = z.object({
  nofStudents: z.number(),
  nofStudentSetGroups: z.number(),
  nofLockedDecisions: z.number(),
  nofNonLockedDecisions: z.number(),
  nofLockedStudents: z.number(),
});
const ProblemAnalysisKpiKeys = Object.keys(ProblemAnalysisKpi.shape) as [
  keyof z.infer<typeof ProblemAnalysisKpi>,
];
const ProblemAnalysisKpiEnum = z.enum(ProblemAnalysisKpiKeys);
const ProblemAnalysisSchema = OptimizerKpiBase.extend({
  id: z.literal("problemAnalysis"),
  kpis: z.array(
    OptimizerKpiBase.extend({
      id: ProblemAnalysisKpiEnum,
    })
  ),
});
const OptimizerKpi = z.discriminatedUnion("id", [
  SolutionAnalysisSchema,
  ProblemAnalysisSchema,
]);

export const OptimizerMeta = z.object({
  userId: z.string(),
  timestamp: z.number(),
});
export type OptimizerMeta = z.infer<typeof OptimizerMeta>;

export const OptimizerOutputSchema = z.object({
  outputPath: z.string(),
  jobId: z.string(),
  internalJobId: z.string(),
  jobType: z.string(),
  status: z.string(),
});
export type OptimizerOutputSchema = z.infer<typeof OptimizerOutputSchema>;

export enum OptimizerStatusCodesEnum {
  Success,
  Impossible,
  ImpossibleInCurrent,
  NotEnoughSpaceOnPossible,
}

const StatusCodes = z.nativeEnum(OptimizerStatusCodesEnum);

const OptimizerStudentStatus = z
  .object({
    studentId: z.number(),
    statuses: z.array(StatusCodes),
  })
  .passthrough();
const OptimizerAssignments = z
  .object({
    students: z.array(z.number()),
    studentSetGroupId: z.number(),
  })
  .passthrough();
export const OptimizerResult = z
  .object({
    kpis: z.array(OptimizerKpi),
    studentStatuses: z.array(OptimizerStudentStatus),
    solution: z
      .object({
        assignments: z.array(OptimizerAssignments),
      })
      .passthrough(),
  })
  .passthrough();
export type OptimizerResult = z.infer<typeof OptimizerResult>;
export const OptimizerError = z
  .object({
    errorCode: z.number(),
    message: z.string(),
  })
  .passthrough();
export type OptimizerError = z.infer<typeof OptimizerError>;

export const OptimizerResponse = z.discriminatedUnion("exit_status", [
  z.object({
    build: z.string(),
    dataVersion: z.number(),
    exit_status: z.literal("success"),
    id: z.string(),
    meta: OptimizerMeta,
    result: OptimizerResult,
  }),
  z.object({
    build: z.string(),
    dataVersion: z.number(),
    exit_status: z.literal("error"),
    id: z.string(),
    error: OptimizerError,
  }),
]);
export type OptimizerResponse = z.infer<typeof OptimizerResponse>;

const isBuffer = (val: unknown): val is Buffer =>
  Buffer.isBuffer(val) || globalThis.Buffer.isBuffer(val);
const bufferSchema = z.unknown().transform((val) => {
  // If it looks like Mongodb Binary
  if (
    val &&
    typeof val === "object" &&
    "buffer" in val &&
    isBuffer(val.buffer)
  ) {
    return val.buffer;
  }

  if (isBuffer(val)) {
    return val;
  }

  throw new Error("Validation failed, Invalid Buffer type ");
});

const JobStatusesConst = [
  "unreceived",
  "failed",
  "pending",
  "completed",
  "allocating",
  "allocated",
] as const;

export const JobStatus = z.enum(JobStatusesConst);
export type JobStatus = z.infer<typeof JobStatus>;

export const jobStatusLevels = {
  [JobStatus.enum.unreceived]: [
    "unreceived",
    "failed",
    "pending",
    "completed",
    "allocating",
    "allocated",
  ] as const,
  [JobStatus.enum.failed]: [
    "failed",
    "pending",
    "completed",
    "allocating",
    "allocated",
  ] as const,
  [JobStatus.enum.pending]: [
    "pending",
    "completed",
    "allocating",
    "allocated",
  ] as const,
  [JobStatus.enum.completed]: ["completed", "allocating", "allocated"] as const,
  [JobStatus.enum.allocating]: ["allocating", "allocated"] as const,
  [JobStatus.enum.allocated]: ["allocated"] as const,
} satisfies Record<JobStatus, Readonly<JobStatus[]>>;

export const ProposalResult = SolutionAnalysisKpi.merge(
  ProblemAnalysisKpi
).merge(NofFailedDecisionsKpi);
export type ProposalResult = z.infer<typeof ProposalResult>;

export const AllocationResult = z
  .object({
    time: z.string().optional(),
    userId: z.string(),
  })
  .merge(
    SaveStudentsResult.pick({
      logId: true,
      failedGroups: true,
    })
  );

const CommonJobSchema = z.object({
  userId: z.string(),
  startTime: z.string(),
  jobId: z.string(),
  inputUrl: z.string(),
  issues: z.number(),
  selection: z.object({
    studentSetGroups: z.number(),
    totalStudents: z.number(),
    incompleteStudents: z.number(),
    courses: z.array(z.number()),
  }),
  inputData: bufferSchema,
});

const PendingJobSchema = CommonJobSchema.extend({
  status: z.literal(JobStatus.enum.pending),
});

export const CompletedJobSchema = CommonJobSchema.extend({
  status: z.literal(JobStatus.enum.completed),
  outputData: bufferSchema,
  proposalResult: ProposalResult,
  outputUrl: z.string(),
});
export type CompletedJobSchema = z.infer<typeof CompletedJobSchema>;
const UnreceivedJobSchema = CommonJobSchema.extend({
  status: z.literal(JobStatus.enum.unreceived),
  error: z.unknown(),
});

const FailedJobSchema = CommonJobSchema.extend({
  status: z.literal(JobStatus.enum.failed),
  error: OptimizerError,
});

const AllocatingJobSchema = CompletedJobSchema.extend({
  status: z.literal(JobStatus.enum.allocating),
});
export type AllocatingJobSchema = z.infer<typeof AllocatingJobSchema>;

const AllocatedJobSchema = CompletedJobSchema.extend({
  allocationResult: AllocationResult,
  status: z.literal(JobStatus.enum.allocated),
});
export type AllocatedJobSchema = z.infer<typeof AllocatedJobSchema>;

const createJobStatusSchemaMap = <
  T extends Record<JobStatus, z.ZodDiscriminatedUnionOption<"status">>,
>(
  map: T
) => map;

const allocateOptimizeJobResultMap = createJobStatusSchemaMap({
  [JobStatus.enum.allocated]: AllocatedJobSchema.extend({
    username: z.object({
      creator: z.string(),
      allocator: z.string(),
    }),
  }),
  [JobStatus.enum.allocating]: AllocatingJobSchema.extend({
    username: z.object({
      creator: z.string(),
    }),
  }),
  [JobStatus.enum.completed]: CompletedJobSchema.extend({
    username: z.object({
      creator: z.string(),
    }),
  }),
  [JobStatus.enum.pending]: PendingJobSchema.extend({
    username: z.object({
      creator: z.string(),
    }),
  }),
  [JobStatus.enum.unreceived]: UnreceivedJobSchema.extend({
    username: z.object({
      creator: z.string(),
    }),
  }),
  [JobStatus.enum.failed]: FailedJobSchema.extend({
    username: z.object({
      creator: z.string(),
    }),
  }),
});

export const AllocateOptimizeJobsResult = z.discriminatedUnion(
  "status",
  Object.values(allocateOptimizeJobResultMap) as [
    (typeof allocateOptimizeJobResultMap)[JobStatus],
    ...(typeof allocateOptimizeJobResultMap)[JobStatus][],
  ]
);

export type AllocateOptimizeJobsResult = z.infer<
  typeof AllocateOptimizeJobsResult
>;

const optimizerJobSchemaMap = createJobStatusSchemaMap({
  [JobStatus.enum.allocated]: AllocatedJobSchema,
  [JobStatus.enum.allocating]: AllocatingJobSchema,
  [JobStatus.enum.completed]: CompletedJobSchema,
  [JobStatus.enum.pending]: PendingJobSchema,
  [JobStatus.enum.unreceived]: UnreceivedJobSchema,
  [JobStatus.enum.failed]: FailedJobSchema,
});
export const OptimizerJobSchema = z.discriminatedUnion(
  "status",
  Object.values(optimizerJobSchemaMap) as [
    (typeof optimizerJobSchemaMap)[JobStatus],
    ...(typeof optimizerJobSchemaMap)[JobStatus][],
  ]
);

const partialOptimizerJobSchemaMap = createJobStatusSchemaMap({
  [JobStatus.enum.allocated]: AllocatedJobSchema.partial().extend({
    jobId: z.string(),
    status: z.literal(JobStatus.enum.allocated),
  }),
  [JobStatus.enum.allocating]: AllocatingJobSchema.partial().extend({
    jobId: z.string(),
    status: z.literal(JobStatus.enum.allocating),
  }),
  [JobStatus.enum.completed]: CompletedJobSchema.partial().extend({
    jobId: z.string(),
    status: z.literal(JobStatus.enum.completed),
  }),
  [JobStatus.enum.pending]: PendingJobSchema.partial().extend({
    jobId: z.string(),
    status: z.literal(JobStatus.enum.pending),
  }),
  [JobStatus.enum.unreceived]: UnreceivedJobSchema.partial().extend({
    jobId: z.string(),
    status: z.literal(JobStatus.enum.unreceived),
  }),
  [JobStatus.enum.failed]: FailedJobSchema.partial().extend({
    jobId: z.string(),
    status: z.literal(JobStatus.enum.failed),
  }),
});
export const PartialOptimizerJobSchema = partialNotUndefined(
  z.discriminatedUnion(
    "status",
    Object.values(partialOptimizerJobSchemaMap) as [
      (typeof partialOptimizerJobSchemaMap)[JobStatus],
      ...(typeof partialOptimizerJobSchemaMap)[JobStatus][],
    ]
  )
);

export type OptimizerJobSchema = z.infer<typeof OptimizerJobSchema>;
export type PartialOptimizerJobSchema = z.infer<
  typeof PartialOptimizerJobSchema
>;

export const AllocationResultsSchema = z.object({
  jobId: z.string(),
  allocationResults: z.array(TrackListWithReason),
});
export type AllocationResultsSchema = z.infer<typeof AllocationResultsSchema>;
