import { EnumLike, z } from "zod";
import {
  EAppId,
  EAppProperties,
  EApplicationObjectTypeGroup,
  EObjectTypeMappingStatuses,
  EOrganizationType,
  EPersonalDataCheck,
  EPersonalInformation as BaseEPersonalInformation,
  EPreferencesFilter,
  EProperty,
  ETokenType,
  EUserVisibility,
} from "@timeedit/types/lib/enums";
import { ERegion } from "@timeedit/types/lib/enums/region.enum";
import { EOrganizationVisibility } from "@timeedit/types/lib/enums/organizationVisibility.enum";
import { ErrorName } from "../types/errors";

/**
 * We would like to be less strict with enum validation, providing defaults 
 * instead of failing when given the wrong inputs (at least for types that are 
 * defined outside of the repo). This is not how defaults normally work 
 * however, so we need to transform before validating.

 * This file collects Zod objects for enums from the type package to be used 
 * in validation. They don't have inferred types. Use the enums themselves as 
 * normal in code when not validating.
 *
 * We are using the 'Z' prefix in place of 'E' to make it clear which enum
 * is being referred to.
 *
 * ATTENTION! If the enum lacks a suitable default value and the parameter is
 * required, we can't do anything about it. We need to validate the enum as
 * is in order to get the correct type, or use a less strict type. So all the 
 * validators come with a Required and an Optional version unless they have 
 * a suitable default.
 */

function enumWithDefault<T extends EnumLike>(
  enumLike: T,
  _default: T[keyof T]
) {
  return z
    .string()
    .optional()
    .nullable()
    .transform((input) =>
      (Object.values(enumLike) as string[]).includes(input ?? "")
        ? input
        : _default
    )
    .pipe(z.nativeEnum(enumLike));
}

function enumOptional<T extends EnumLike>(enumLike: T) {
  return z
    .string()
    .optional()
    .nullable()
    .transform((input) =>
      input !== undefined &&
      (Object.values(enumLike) as string[]).includes(input ?? "")
        ? input
        : undefined
    )
    .pipe(z.nativeEnum(enumLike).optional());
}

export const ZApplicationObjectTypeGroup = enumWithDefault(
  EApplicationObjectTypeGroup,
  EApplicationObjectTypeGroup.NONE
);

export const ZAppProperties = enumWithDefault(
  EAppProperties,
  EAppProperties.NONE
);

export const ZAppIdOptional = enumOptional(EAppId);

export const ZAppIdRequired = z.nativeEnum(EAppId);

export const ZRegionOptional = enumOptional(ERegion);

export const ZRegionRequired = z.nativeEnum(ERegion);

export const ZObjectTypeMappingStatusesOptional = enumOptional(
  EObjectTypeMappingStatuses
);

export const ZObjectTypeMappingStatusesRequired = z.nativeEnum(
  EObjectTypeMappingStatuses
);

export const ZOrganizationTypeOptional = enumOptional(EOrganizationType);

export const ZOrganizationTypeRequired = z.nativeEnum(EOrganizationType);

export const ZOrganizationVisibilityRequired = z.nativeEnum(
  EOrganizationVisibility
);

export const ZOrganizationVisibilityOptional = enumOptional(
  EOrganizationVisibility
);

export const ZTokenTypeOptional = enumOptional(ETokenType);

export const ZTokenTypeRequired = z.nativeEnum(ETokenType);

export const ZUserVisibility = enumWithDefault(
  EUserVisibility,
  EUserVisibility.STANDARD
);

export const ZPersonalDataCheck = enumWithDefault(
  EPersonalDataCheck,
  EPersonalDataCheck.STANDARD
);

export enum EPersonalInformation {
  YES = BaseEPersonalInformation.YES,
  NO = BaseEPersonalInformation.NO,
  STANDARD = BaseEPersonalInformation.STANDARD,
}

export const ZPersonalInformation = enumWithDefault(
  EPersonalInformation,
  EPersonalInformation.STANDARD
);

export const ZPreferencesFilterOptional = enumOptional(EPreferencesFilter);

export const ZPreferencesFilterRequired = z.nativeEnum(EPreferencesFilter);

export const ZPropertyOptional = enumOptional(EProperty);

export const ZPropertyRequired = z.nativeEnum(EProperty);

export const ZErrorName = z.nativeEnum(ErrorName);
